import React, { useState, useRef } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Templates/Layout";
import SEO from "../utils/SEO";
import { Reveal, Tween } from "react-gsap";
import Image from "../components/Global/Image";
import Background from "../components/Global/Background";
import SmartText from "../utils/TextHandler";
import Link from "../components/Global/Link";
import Progress from "../components/Templates/Progress";
import Modal, { ModalBody, ModalFooter } from "../components/Templates/Modal";
import ShareLinks from "../components/Global/ShareLinks";

function oddOrEven(x) {
  return x & 1 ? "odd" : "even";
}

// Markup
const IndexPage = (props) => {
  const { data } = props;

  const [modalNav, setModalNav] = useState(false);

  let doc;
  if (data) {
    doc = JSON.parse(data.index.content);
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.headline,
  };

  // Handles the Join team form to open success modal

  const modalRefTeamA = useRef();
  const modalRefTeamB = useRef();
  const modalRefRewards = useRef();
  const modelRefFormSuccessA = useRef();
  const modelRefFormSuccessB = useRef();

  function navigateModalScreens(to) {
    modalRefTeamA.current.closeModal();
    modalRefTeamB.current.closeModal();
    modalRefRewards.current.closeModal();
    modelRefFormSuccessA.current.closeModal();
    modelRefFormSuccessB.current.closeModal();
    setModalNav(false);
    to.current.openModal();
    return;
  }
  function navigateModalScreensFromIndex(to) {
    modalRefTeamA.current.closeModal();
    modalRefTeamB.current.closeModal();
    modalRefRewards.current.closeModal();
    modelRefFormSuccessA.current.closeModal();
    modelRefFormSuccessB.current.closeModal();
    setModalNav(true);
    to.current.openModal();
    return;
  }

  function validateForm(form) {
    let errors = [];
    let input = "";
    let terms = "";
    //    const userRegExp = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;

    const userRegExp = /^(?=.*\d).{32,}$/;

    if (form === "a") {
      input = document.getElementById("userA").value;
      terms = document.getElementById("termsA").checked;
    } else if (form === "b") {
      input = document.getElementById("userB").value;
      terms = document.getElementById("termsB").checked;
    }

    if (terms !== true) {
      errors.push("Please agree to the terms");
    }
    if (!userRegExp.test(input)) {
      errors.push("Please ensure your UserID is correct");
    }

    const test = terms && userRegExp.test(input);

    if (test) {
      return true;
    }

    let errorMessage = "";

    for (var i = 0; i < errors.length; i++) {
      errorMessage += "- " + errors[i] + "\n";
    }

    return errorMessage;
  }

  const handleSubmitTeamA = (e) => {
    e.preventDefault();
    let myForm = document.getElementById("teamA");
    let formData = new FormData(myForm);
    let validated = validateForm("a");

    if (validated === true) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => navigateModalScreens(modelRefFormSuccessA))
        .catch((error) => console.log(error));
    } else {
      alert(validated);
    }
  };

  const handleSubmitTeamB = (e) => {
    e.preventDefault();
    let myForm = document.getElementById("teamB");
    let formData = new FormData(myForm);
    let validated = validateForm("b");

    if (validated === true) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => navigateModalScreens(modelRefFormSuccessB))
        .catch((error) => console.log(error));
    } else {
      alert(validated);
    }
  };

  return (
    <Layout>
      <SEO openGraph={openGraph} meta={doc.meta} />

      <section>
        <div className="container-fluid position-relative">
          <div className="row">
            <Background
              element="div"
              src={doc.image_background_team_a?.filename}
              sm="960x540"
              md="1920x1080"
              lg="1920x1080"
              className="col-6 text-center brand-background-blue-light"
            >
              <div className="py-5 d-flex flex-column justify-content-center align-items-center">
                <Tween
                  from={{
                    opacity: 0,
                    transform: "scale(0)",
                  }}
                  ease="back.out(2)"
                  duration={1}
                  delay={0.5}
                >
                  <div>
                    <Image
                      src={doc.image_logo_team_a?.filename}
                      sm="320x320"
                      md="720x720"
                      lg="1080x1080"
                      alt="Team A"
                      className="brand-hero-image mb-3"
                    />
                  </div>
                </Tween>
                <Link
                  button
                  className="brand-btn-hex"
                  onClick={() => navigateModalScreens(modalRefTeamA)}
                >
                  Join team
                </Link>
              </div>
            </Background>
            <Background
              element="div"
              src={doc.image_background_team_b?.filename}
              sm="960x540"
              md="1920x1080"
              lg="1920x1080"
              className="col-6 text-center brand-background-blue"
            >
              <div className="py-5 d-flex flex-column justify-content-center align-items-center">
                <Tween
                  from={{
                    opacity: 0,
                    transform: "scale(0)",
                  }}
                  ease="back.out(2)"
                  duration={1}
                  delay={0.6}
                >
                  <div>
                    <Image
                      src={doc.image_logo_team_b?.filename}
                      sm="320x320"
                      md="720x720"
                      lg="1080x1080"
                      alt="Team A"
                      className="brand-hero-image mb-3"
                    />
                  </div>
                </Tween>
                <Link
                  button
                  className="brand-btn-hex"
                  onClick={() => navigateModalScreens(modalRefTeamB)}
                >
                  Join team
                </Link>
              </div>
            </Background>
          </div>
          <div className="position-absolute top-0 start-50 bottom-0 brand-background-blue h-100 p-1"></div>
          <div className="position-absolute top-50 start-50 translate-middle">
            <span className="brand-font brand-text-white brand-font-shadow fs-1">
              VS
            </span>
          </div>
        </div>
      </section>

      <Progress
        teamA={doc.title_team_a}
        teamB={doc.title_team_b}
        progressTeamA={doc.progress_team_a}
        progressTeamB={doc.progress_team_b}
        imageProgressA={doc.image_progress_team_a}
        imageProgressB={doc.image_progress_team_b}
        deadline={doc.deadline}
        quote_team_a_1={doc.quote_team_a_1}
        quote_team_a_2={doc.quote_team_a_2}
        quote_team_a_3={doc.quote_team_a_3}
        quote_team_b_1={doc.quote_team_b_1}
        quote_team_b_2={doc.quote_team_b_2}
        quote_team_b_3={doc.quote_team_b_3}
      />

      <Background
        element="section"
        src={doc.background?.filename}
        sm="960x960"
        md="1920x1920"
        lg="1920x1920"
        className="brand-background-static"
      >
        <div className="container py-5">
          {doc.headline && (
            <div className="row mb-5">
              <h2 className="text-center brand-font brand-text-white brand-font-shadow">
                {doc.headline}
              </h2>
            </div>
          )}

          {doc.sections.map((item, idx) => {
            const oddEven = oddOrEven(idx);
            return (
              <Reveal>
                <div
                  className={`row overflow-hidden brand-background-white brand-radius shadow justify-content-center align-items-center mx-2 mx-md-0 ${
                    idx !== doc.sections.length - 1 && "mb-5"
                  } ${oddEven === "odd" ? "flex-row flex-md-row-reverse" : ""}`}
                >
                  <div className="col-12 col-md-6 text-center py-3">
                    <Image
                      src={item.image?.filename}
                      sm="480x270"
                      md="960x540"
                      lg="1280x720"
                      className="w-100"
                    />
                  </div>
                  <div className="col-12 col-md-6 p-3 pt-0 p-md-5">
                    {item.title && (
                      <Tween
                        from={{
                          opacity: 0,
                          transform: "translate3d(50px, 0, 0)",
                        }}
                        ease="back.out(1)"
                        duration={1}
                        delay={0.5}
                      >
                        <h3 className="brand-font brand-text-blue-dark">
                          {item.title}
                        </h3>
                      </Tween>
                    )}
                    {item.body && (
                      <Tween
                        from={{
                          opacity: 0,
                          transform: "translate3d(50px, 0, 0)",
                        }}
                        ease="back.out(1)"
                        duration={1}
                        delay={0.7}
                      >
                        <div>
                          <SmartText>{item.body}</SmartText>
                        </div>
                      </Tween>
                    )}

                    <Tween
                      from={{
                        opacity: 0,
                        transform: "translate3d(50px, 0, 0)",
                      }}
                      ease="back.out(1)"
                      duration={1}
                      delay={0.9}
                    >
                      <>
                        {item.button && item.action === "overview" && (
                          <div>
                            <Link
                              button
                              onClick={() =>
                                navigateModalScreens(modalRefRewards)
                              }
                            >
                              {item.button}
                            </Link>
                          </div>
                        )}
                        {item.button && item.action === "teama" && (
                          <div>
                            <Link
                              button
                              onClick={() =>
                                navigateModalScreens(modalRefTeamA)
                              }
                            >
                              {item.button}
                            </Link>
                          </div>
                        )}
                        {item.button && item.action === "teamb" && (
                          <div>
                            <Link
                              button
                              onClick={() =>
                                navigateModalScreens(modalRefTeamB)
                              }
                            >
                              {item.button}
                            </Link>
                          </div>
                        )}
                        {item.action === "social" && (
                          <div>
                            <ShareLinks
                              meta={doc.meta}
                              openGraph={doc.openGraph}
                              facebook
                              twitter
                              email
                              title="I’m taking part in #RebelRacingRivals!"
                              description="I’m taking part in #RebelRacingRivals!"
                              hashtags={["RebelRacingRivals", "RebelRacing"]}
                            />
                          </div>
                        )}
                      </>
                    </Tween>
                  </div>
                </div>
              </Reveal>
            );
          })}
        </div>
      </Background>

      <Modal ref={modalRefTeamA} title={`Join ${doc.title_team_a}`}>
        <form
          name="teamA"
          id="teamA"
          method="POST"
          data-netlify="true"
          onSubmit={handleSubmitTeamA}
          noValidate
        >
          <input type="hidden" name="form-name" value="teamA" />
          <ModalBody>
            <Background
              src={doc.image_modal_team_a?.filename}
              sm="320x0"
              md="480x0"
              lg="720x0"
              alt={doc.image_modal_team_a.alt}
              className="w-100 brand-radius mb-3 ratio ratio-16x9"
            >
              <div className="d-flex justify-content-end align-items-end">
                <Link
                  onClick={() => navigateModalScreens(modalRefRewards)}
                  icon
                  className="brand-font brand-text-white text-decoration-none p-2"
                >
                  Check out the rewards
                </Link>
              </div>
            </Background>
            {doc.signup_team_a && (
              <SmartText className="small">{doc.signup_team_a}</SmartText>
            )}
            <div class="form-floating mb-1">
              <input
                type="text"
                class="form-control"
                id="userA"
                name="user"
                aria-describedby="userHelpA"
                pattern="^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$"
                required
              />
              <label for="user">UserID</label>
            </div>
            <div id="userHelpA" class="form-text brand-font-small mb-4">
              Find your UserID in the Rebel Racing game settings screen. Tap the
              long code in the bottom left to copy and paste it into this field.
            </div>
            <div class="mb-3 form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="termsA"
                name="terms"
                required
              />
              <label
                class="form-check-label small brand-font-smaller"
                for="terms"
              >
                This microsite is owned by Hutch Games Ltd ("Hutch", "we",
                "our"). If you register here we will use your User ID to
                identify the winners when this team competition is over, and
                allocate prizes accordingly. By entering your User ID here you
                agree to share it with the microsite and for Hutch to process
                your User ID for that purpose. This notice supplements our
                Privacy &amp; Cookies Policy, which you can find{" "}
                <Link to="https://www.hutch.io/privacy/">here</Link>. Our
                <Link to="https://www.hutch.io/terms-of-service/">
                  Terms of Service
                </Link>{" "}
                apply.
              </label>
            </div>
            <input type="text" name="team" value="A" hidden required />
          </ModalBody>
          <ModalFooter>
            {modalNav === true && (
              <Link
                button
                onClick={() => navigateModalScreens(modalRefRewards)}
              >
                Back
              </Link>
            )}
            <Link button="real" type="submit">
              Join Team
            </Link>
          </ModalFooter>
        </form>
      </Modal>

      <Modal ref={modalRefTeamB} title={`Join ${doc.title_team_b}`}>
        <form
          name="teamB"
          id="teamB"
          method="POST"
          data-netlify="true"
          onSubmit={handleSubmitTeamB}
          noValidate
        >
          <input type="hidden" name="form-name" value="teamB" />
          <ModalBody>
            <Background
              src={doc.image_modal_team_b?.filename}
              sm="320x0"
              md="480x0"
              lg="720x0"
              alt={doc.image_modal_team_b.alt}
              className="w-100 brand-radius mb-3 ratio ratio-16x9"
            >
              <div className="d-flex justify-content-end align-items-end">
                <Link
                  onClick={() => navigateModalScreens(modalRefRewards)}
                  icon
                  className="brand-font brand-text-white text-decoration-none p-2"
                >
                  Check out the rewards
                </Link>
              </div>
            </Background>
            {doc.signup_team_b && (
              <SmartText className="small">{doc.signup_team_b}</SmartText>
            )}

            <div class="form-floating mb-1">
              <input
                type="text"
                class="form-control"
                id="userB"
                name="user"
                aria-describedby="userHelpB"
                pattern="^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$"
                required
              />
              <label for="user">UserID</label>
            </div>
            <div id="userHelpB" class="form-text brand-font-small mb-4">
              Find your UserID in the Rebel Racing game settings screen. Tap the
              long code in the bottom left to copy and paste it into this field.
            </div>
            <div class="mb-3 form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="termsB"
                name="terms"
                required
              />
              <label
                class="form-check-label small brand-font-smaller"
                for="terms"
              >
                This microsite is owned by Hutch Games Ltd ("Hutch", "we",
                "our"). If you register here we will use your User ID to
                identify the winners when this team competition is over, and
                allocate prizes accordingly. By entering your User ID here you
                agree to share it with the microsite and for Hutch to process
                your User ID for that purpose. This notice supplements our
                Privacy &amp; Cookies Policy, which you can find{" "}
                <Link to="https://www.hutch.io/privacy/">here</Link>. Our
                <Link to="https://www.hutch.io/terms-of-service/">
                  Terms of Service
                </Link>{" "}
                apply.
              </label>
            </div>
            <input type="text" name="team" value="B" hidden required />
          </ModalBody>
          <ModalFooter>
            {modalNav === true && (
              <Link
                button
                onClick={() => navigateModalScreens(modalRefRewards)}
              >
                Back
              </Link>
            )}
            <Link button="real" type="submit">
              Join Team
            </Link>
          </ModalFooter>
        </form>
      </Modal>

      <Modal ref={modalRefRewards} title="Rewards">
        <ModalBody>
          <div className="row align-items-center justify-content-between mb-3">
            {doc.title_team_a && (
              <div className="col">
                <h4 className="brand-font brand-text-team-a mb-0">
                  {doc.title_team_a}
                </h4>
              </div>
            )}
            <div className="col-auto">
              <Link
                onClick={() => navigateModalScreensFromIndex(modalRefTeamA)}
                button
              >
                Select team
              </Link>
            </div>
          </div>
          {doc.description_team_a && (
            <div className="row">
              <SmartText className="small">{doc.description_team_a}</SmartText>
              <p className="small">Possible prizes if this team wins:</p>
            </div>
          )}
          {doc.prizes_team_a && (
            <div className="row gy-2">
              {doc.prizes_team_a.map((item, idx) => {
                return (
                  <div className="col-6 small align-items-center d-flex">
                    <div
                      className="ratio ratio-1x1 me-2 d-inline-block"
                      style={{ width: "40px" }}
                    >
                      <div className="brand-background-grey rounded-circle">
                        <Image
                          src={item.image?.filename}
                          sm="40x40"
                          md="40x40"
                          lg="40x40"
                          alt={item.label}
                        />
                      </div>
                    </div>
                    <span className="d-inline-block">{item.label}</span>
                  </div>
                );
              })}
            </div>
          )}
          <hr />
          <div className="row align-items-center justify-content-between mb-3">
            {doc.title_team_b && (
              <div className="col">
                <h4 className="brand-font brand-text-team-b mb-0">
                  {doc.title_team_b}
                </h4>
              </div>
            )}
            <div className="col-auto">
              <Link
                onClick={() => navigateModalScreensFromIndex(modalRefTeamB)}
                button
              >
                Select team
              </Link>
            </div>
          </div>
          {doc.description_team_b && (
            <div className="row">
              <SmartText className="small">{doc.description_team_b}</SmartText>
              <p className="small">Possible prizes if this team wins:</p>
            </div>
          )}
          {doc.prizes_team_b && (
            <div className="row gy-2">
              {doc.prizes_team_b.map((item, idx) => {
                return (
                  <div className="col-6 small align-items-center d-flex">
                    <div
                      className="ratio ratio-1x1 me-2 d-inline-block"
                      style={{ width: "40px" }}
                    >
                      <div className="brand-background-grey rounded-circle">
                        <Image
                          src={item.image?.filename}
                          sm="40x40"
                          md="40x40"
                          lg="40x40"
                          alt={item.label}
                        />
                      </div>
                    </div>
                    <span className="d-inline-block">{item.label}</span>
                  </div>
                );
              })}
            </div>
          )}
          <hr />

          <div className="row text-center small">
            <Link to="https://hutch.helpshift.com/a/rebel-racing/?s=what-are-the-crate-drop-rates-1586256131&f=what-are-the-drop-rates-for-the-rebel-racing-rivals-crates&p=all">
              Check out the drop rates
            </Link>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        ref={modelRefFormSuccessA}
        title="You're in Pacific Coast Cruisers!"
      >
        <ModalBody>
          {doc.thanks_team_a && <SmartText>{doc.thanks_team_a}</SmartText>}
        </ModalBody>
        <ModalFooter>
          <ShareLinks
            meta={doc.meta}
            openGraph={doc.openGraph}
            facebook
            twitter
            email
            title="I’m taking part in #RebelRacingRivals! Join my team, Pacific Coast Cruisers"
            description="I’m taking part in #RebelRacingRivals! Join my team, Pacific Coast Cruisers"
            hashtags={["PACIFICCOASTCRUISERS"]}
          />
        </ModalFooter>
      </Modal>

      <Modal ref={modelRefFormSuccessB} title="You're in Desert Town Drifters!">
        <ModalBody>
          {doc.thanks_team_b && <SmartText>{doc.thanks_team_b}</SmartText>}
        </ModalBody>
        <ModalFooter>
          <ShareLinks
            meta={doc.meta}
            openGraph={doc.openGraph}
            facebook
            twitter
            email
            title="I’m taking part in #RebelRacingRivals! Join my team, Desert Town Drifters"
            description="I’m taking part in #RebelRacingRivals! Join my team, Desert Town Drifters"
            hashtags={["DESERTTOWNDRIFTERS"]}
          />
        </ModalFooter>
      </Modal>
    </Layout>
  );
};

export default IndexPage;

export const QUERY = graphql`
  query IndexPageQuery {
    index: storyblokEntry(slug: { eq: "rebelracingrivals" }) {
      content
    }
  }
`;
