import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Reveal, Tween } from "react-gsap";

const Countdown = (props) => {
  const { date } = props;
  const [countdownDate, setCountdownDate] = useState(new Date(date).getTime());
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const tick = setInterval(() => setNewTime(), 1000);
    return () => clearInterval(tick);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("This will run after 1 second!");
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const setNewTime = () => {
    if (countdownDate) {
      const currentTime = new Date().getTime();

      const distanceToDate = countdownDate - currentTime;

      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
      );
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

      //hasDatePast = hours < 0 ? true : false;
      if (numbersToAddZeroTo.includes(days)) {
        days = `0${days}`;
      } else if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`;
      } else if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      } else if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }

      setState({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });
    }
  };

  if (state.seconds < 0) {
    // This event has happened so we show an empty div
    return <div />;
  } else {
    // The event is in the future so we display the countdown component
    return (
      <div className="brand-background-blue brand-hide-ios">
        <div className="container">
          <Tween
            from={{ opacity: 0, scale: 0, ease: "back", delay: 3 }}
            duration={1}
          >
            <div className="row brand-text-white text-center justify-content-center align-items-center">
              <div className="col-3 col-md-2 col-lg-1">
                <div className="brand-font fs-2 mb-0">{state.days || "0"}</div>
                <div className="brand-font-small text-uppercase brand-text-white">
                  days
                </div>
              </div>

              <div className="col-3 col-md-2 col-lg-1">
                <div className="brand-font fs-2">{state.hours || "00"}</div>
                <div className="brand-font-small text-uppercase brand-text-white">
                  hours
                </div>
              </div>

              <div className="col-3 col-md-2 col-lg-1">
                <div className="brand-font fs-2">{state.minutes || "00"}</div>
                <div className="brand-font-small text-uppercase brand-text-white">
                  minutes
                </div>
              </div>

              <div className="col-3 col-md-2 col-lg-1">
                <div className="brand-font fs-2">{state.seconds || "00"}</div>
                <div className="brand-font-small text-uppercase brand-text-white">
                  seconds
                </div>
              </div>
            </div>
          </Tween>
        </div>
      </div>
    );
  }
};

export default Countdown;

Countdown.propTypes = {
  date: PropTypes.string,
};

Countdown.defaultProps = {
  date: null,
};
